import React, { useState, useEffect } from 'react'
import { Layout } from 'antd'
import { Route, withRouter, Redirect } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import LeftSiderMenu from 'Layouts/Dashboard/LeftSiderMenu'
import Header from './Header'
import '../Dashboard/index.scss'
// import loginlayout_background from "Assets/images/login_background.svg";

const { Content, Footer } = Layout

const AdminLayout = withRouter((props) => {
  // const [menuCollapse, setMenuCollapse] = useState(true)

  const fetchMe = useStoreActions((actions) => actions.auth.fetchMe)
  const isMenuCollapsed = useStoreState((state) => state.sideMenu.isMenuCollapsed)
  const setMenuCollapse = useStoreActions((action) => action.sideMenu.setMenuCollapse)

  useEffect(() => {
    fetchMe()
  }, [fetchMe])

  return (
    <Layout id="dashboard_layout_contain">
      <LeftSiderMenu menuCollapse={isMenuCollapsed} setMenuCollapse={setMenuCollapse} />
      <Layout className={`site-layout-contain ${!isMenuCollapsed && 'menu-expanded'}`}>
        <Header />
        <Content className="dashboard-layout-content">
          <div className="site-layout-wrapper">{props.children}</div>
        </Content>
        {/* <Footer className="dashboard-layout-footer" style={{ textAlign: 'center' }}>
          Kariba ©2021 Powered by Seedcom
        </Footer> */}
      </Layout>
    </Layout>
  )
})

const AdminRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('Authorization')
  if (!token) return <Redirect to="/login" />

  return (
    <Route
      {...rest}
      render={(props) => (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  )
}

export { AdminLayout, AdminRoute }
