import React, { useState, useRef } from 'react'
import { Layout, Button, Avatar, Card, Dropdown, Menu, Popover } from 'antd'
import { SettingOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'

import { useClickOutside } from 'Hooks'
import { generateColor } from 'Utils'
import SearchCustomers from './SearchCustomers'
import BreadCrumb from 'Components/BreadCrumb'
const { Header } = Layout

const menu = (
  <Menu>
    <Menu.Item key="1" icon={<UserOutlined />}>
      1st menu item
    </Menu.Item>
    <Menu.Item key="2" icon={<UserOutlined />}>
      2nd menu item
    </Menu.Item>
    <Menu.Item key="3" icon={<UserOutlined />}>
      3rd item
    </Menu.Item>
  </Menu>
)

const AvatarDropDownCard = React.memo((props) => {
  const { name, email, sortName, avaRef, logOut, color } = props

  const history = useHistory()

  return (
    <div>
      <Card
        hoverable={false}
        className="avatar-card-dropdown-wrapper"
        cover={
          <Avatar
            shape={'circle'}
            size={54}
            className="card-cover-avatar"
            style={{ background: color }}
          >
            {sortName}
          </Avatar>
        }
      >
        <div className="inner-card">
          <strong className="user-name">{name}</strong>
          <span className="user-email">{email}</span>
          <div className="button-wrapper">
            <Button
              icon={<SettingOutlined />}
              onClick={() => history.push('/settings-account')}
              className="settings-button"
              style={{ border: 'none' }}
            >
              Settings
            </Button>

            <Button
              style={{ border: 'none' }}
              icon={<LogoutOutlined />}
              onClick={logOut}
              className="logout-button"
            >
              Logout
            </Button>
          </div>
        </div>
      </Card>
    </div>
  )
})

export default (props) => {
  const { bpCollapsed, toggleMenu } = props
  const userInfo = useStoreState((state) => state.auth.userInfo)
  const _name = userInfo.full_name || 'Unknown'
  const _nameArr = _name.split(' ')
  const length = _nameArr.length
  const sortName = length === 1 ? _nameArr[0][0] : _nameArr[0][0] + _nameArr[length - 1][0]

  const color = generateColor(_name)

  const avaRef = useRef()
  const btnRef = useRef()

  const [cardVisible, setCardVisible] = useState(false)
  const logOut = useStoreActions((actions) => actions.auth.logOut)

  useClickOutside(avaRef, btnRef, () => setCardVisible(false))
  return (
    <Header className="site-layout-header">
      <div className="flex items-center">{/* <SearchCustomers /> */}</div>
      <div className="right-header">
        <Popover
          className="user-infor"
          placement="bottomRight"
          trigger="click"
          visible={cardVisible}
          onVisibleChange={() => {
            setCardVisible(!cardVisible)
          }}
          content={
            <AvatarDropDownCard
              sortName={sortName}
              name={_name}
              logOut={logOut}
              color={color}
              {...userInfo}
            />
          }
        >
          <Avatar className="user-avatar" style={{ background: color }}>
            {sortName}
          </Avatar>
          <span className="user-name">{_name}</span>
        </Popover>
      </div>
    </Header>
  )
}
