import React from 'react'
import { Menu, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import MENU from './Menu.constants'
import { hasPermission } from 'Utils'
import { SettingOutlined } from '@ant-design/icons'
const { Item, SubMenu } = Menu

const MenuList = ({ selectedKeys, openKeys, menuCollapse, onClick, handleOpenChange }) => {
  const roles = useStoreState((state) => state.auth.roles)
  const setMenuCollapse = useStoreActions((action: any) => action.sideMenu.setMenuCollapse)
  const onGoTo = (route) => {
    if (route.includes('analytics')) {
      return `${route}${window.location.search}`
    }

    return route
  }

  if (!roles) return <></>
  return (
    <Menu
      mode="inline"
      selectedKeys={selectedKeys}
      onClick={onClick}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
      inlineCollapsed={menuCollapse}
    >
      {MENU.map((item) => {
        if (item.subMenus.length) {
          const hasPerm = hasPermission(roles, item.allowRoles)
          if (!hasPerm) return <></>
          return (
            <SubMenu key={item.id} icon={<item.icon />} title={item.name} className="sub-menu">
              {item.subMenus.map((submenu) => {
                const hasPerm = hasPermission(roles, submenu.allowRoles)
                if (!hasPerm) return <></>
                return (
                  <Item key={submenu.id}>
                    <Link
                      onClick={() => {
                        setMenuCollapse()
                      }}
                      to={onGoTo(submenu.route)}
                    >
                      {submenu.name}
                    </Link>
                  </Item>
                )
              })}
            </SubMenu>
          )
        }
        const hasPerm = hasPermission(roles, item.allowRoles)
        if (!hasPerm) return <></>
        return (
          <Item key={item.id} icon={<item.icon />}>
            <Link to={item.route}>{item.name}</Link>
          </Item>
        )
      })}
      <Link
        className={`absolute bottom-0 left-0 p-4 mx-2 rounded ${menuCollapse ? 'w-10' : 'w-40'}`}
        to="/admin"
      >
        <div className="flex items-center gap-2 cursor-pointer">
          <SettingOutlined className=" text-blue-500" />
          {!menuCollapse && <span>Admin</span>}
        </div>
      </Link>
    </Menu>
  )
}

export default MenuList
